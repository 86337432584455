<template>
  <div style="font-family:times new roman;">
    <div class="no-print bg-black" style="color:White;">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-2 mt-4">
            <button
              type="button"
              @click="back()"
              class="btn btn-sm btn-outline-danger no-print "
            ><span class="typcn typcn-arrow-left"></span> Go Back!</button>
          </div>
          <div class="col-sm-6">
            <button type="button" @click="print()" class="btn btn-sm btn-primary float-right pr-4 mt-4"><span class="typcn typcn-printer"></span> Print</button>
          </div>
        </div>
      </div>
      <hr class="style13" />
    </div>
    <!--  -->
    <!-- DIMULAI DARI SINI PRINT PAGE -->
    <!--  -->
    <div style>
      <div class="row justify-content-center">
        <div class="col-3">
            <img
        :src="$store.state.data.toko.logo"
        class="kinoLightBox img-fluid rounded-top z-depth-2"
        style="margin-left:10px;position:absolute;left:100px;height:126px;"
        alt="no found"
      />
        </div>
        <div class="col-6">
         <p class="pl-3 text-center" style="font-size:32px;line-height:1.5;line-spacing:3px;">
           <!-- TOKO -->
          TOKO <span class="font-bold">{{$store.state.data.toko.nama_toko}}</span> 
            <br />
          </p>
          <p
            class="pl-3 text-center"
            style="font-size:18px;"
          ></p>
      <p
        class="text-center"
        style="font-size:18px;"
      >{{$store.state.data.toko.alamat}}</p>
            <br />
            <br />
        </div>
        <div class="col-3"></div>
      </div>
      <img src="@/static/photo/line.jpg" class="img-fluid" style="width:100%;height:20px;" />
          <br>
      
     <!-- BANYAK DATA -->
     <div class="row justify-content-center mr-2">
       <div class="col-md-8">
                <table border="1" class="table table-sm table-striped">
                    <tr  style=" border: 1px solid black;">
                        <th class="capitalize" style=" border: 1px solid black;font-size:13px;" v-for="(item, index) in keys" :key="index+'keys'">{{item}}</th>
                    </tr>
                    <tr  style=" border: 1px solid black;" v-for="(item, index) in datanya" :key="index+'datanya'">
                        <td  style=" border: 1px solid black;font-size:13px;" v-for="(item2, index2) in keys" :key="index2+'key2'">
                            {{item[item2]}}    
                        </td>   
                    </tr>
                </table>
       </div>
     </div>
      <!-- TABLE END -->
      <br />
      <br />
      <!-- TTD -->
      <!-- TTD -->
      <!-- TTD -->
      <div class="float-right text-2xl" style="padding-right:70px;">
        <p>Paringin, {{format(new Date())}}</p>
        <p class="text-center">Atasan langsung</p>
        <br />
        <br />
        <br />

        <p class="text-2xl text-center" style="">{{$store.state.data.toko.owner}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import autophp from '@/plugins/autophp_kasir';
let sdb = new autophp();
var id = require("date-fns/locale/id");
export default {
  data() {
    return {
      datanya:[],
      pembatas:1,
      less: ["UrutID","Gambar",'_id'],
      thnya: [],
      records: "",
      key: [],
      keys: [],
      vdata:{
        jabatan:"Kepala Bagian",
        nama:"....................................."
      },
      listnya: [
        { Nama: "Taufik", Jabatan: "Programmer" },
        { Nama: "Akbar", Jabatan: "Desainer" },
        { Nama: "Maliki", Jabatan: "Web Master" },
        { Nama: "Kino", Jabatan: "Traveller" }
      ],
      ttd_unit_head: "",
      ttd_deputy: "",
      ttds1: "",
      ttds2: "",
      ttds3: "",
      ttd: {
        Nama: ".................................",
        Jabatan: "Kepala"
      }
    };
  },
 filters: {
  nounderline: function (value) {
    if(value){
      return value.replace('_',' ')
    }
  }
},
  methods: {
    getKeys() {
      let data = this.$store.state.print2[0];
      data = Object.keys(data);
      this.key = data;
    },
    ganti(e) {
      console.log(e);
      this.ttd.Nama = JSON.parse(e).Nama;
      this.ttd.Jabatan = JSON.parse(e).Jabatan;
      this.$forceUpdate();
    },
    print() {
      document.addEventListener(
        "deviceready",
        function () {
          // cordova.plugins.printer is now available
          cordova.plugins.printer.print();
        },
        false
      );
      window.print();
    },
    back() {
      this.$router.go(-1);
    },
    format(date) {
      return this.$datefns.format(date, "DD MMMM YYYY");
    }
  },
  mounted() {
    // sdb.collection("app_kasir_toko",false).doc().select(`select * from app_kasir_toko`).then(res => {
    //     console.log(res);
    // });
    this.datanya=this.$store.state.data.report_masuk;
    this.keys=Object.keys(this.$store.state.data.report_masuk[0])
    this.$forceUpdate();
    // let data = Object.keys(this.$store.state.print2[0]);
    // if(data.length > 20){
    //   this.pembatas=2;
    // }else if(data.length > 30){
    //   this.pembatas=3
    // }
    // this.less = this.$route.query.less.split(',')
    // this.$forceUpdate()
    // this.datanya = this.$_.chunk(data,this.pembatas);
    // console.log(this.pembatas,this.datanya)
    // setTimeout(() => {
    //   this.getKeys();
    // }, 500);
  }
};
</script>
<style scoped>
.setting {
  padding: 10px;
  line-heigth: 1.5;
}
td {
  font-size: 19px;
  line-heigth: 1.5;
}
@page {
  size: auto; /* auto is the initial value */

  /* this affects the margin in the printer settings */
  /* margin: 2cm 2cm 3cm 3cm; */
  margin:10%;
}
@media print {

    .no-print,
    .no-print * {
        display: none !important;
    }

    .table-print {
        border: 1px solid black;
    }
}
body {
  /* this affects the margin on the content before sending to printer */
  margin: 2cm 2cm 3cm 3cm;
  margin: 0px;
}
</style>